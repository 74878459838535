<template>
  <b-overlay
    :show="item === null || loading.history"
    rounded="sm"
  >
    <b-tabs v-if="item">
      <b-tab :title="$t('market.general.title')">
        <b-row>
          <b-col
            cols="12"
            md="8"
          >
            <b-card>
              <b-form>
                <b-tabs pills>
                  <b-tab :title="$t('market.products.tabs.general.label')">
                    <b-tabs>
                      <b-tab
                        v-for="language in languages"
                        :key="language.code"
                      >
                        <template #title>
                          <b-img
                            :src="require('@/assets/images/flags/' + language.code + '.svg')"
                            height="16"
                            width="16"
                            class="mr-1"
                          />
                          <span class="d-none d-sm-inline">{{ language.title }}</span>
                        </template>
                        <b-row>
                          <b-col
                            cols="24"
                            md="12"
                          >
                            <b-form-group
                              :label="$t('form.title.label')"
                              :label-for="'title.' + language.code"
                            >
                              <b-form-input
                                :id="'title.' + language.code"
                                v-model="item.title[language.code]"
                                :state="errors && errors['title.' + language.code] ? false : null"
                              />
                              <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                                {{ errors['title.' + language.code][0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="24"
                            md="12"
                          >
                            <b-form-group
                              :label="$t('form.description.label')"
                              :label-for="'description.' + language.code"
                              :state="errors && errors['description.' + language.code] ? false : null"
                            >
                              <b-form-textarea
                                :id="'description.' + language.code"
                                v-model="item.description[language.code]"
                                :state="errors && errors['description.' + language.code] ? false : null"
                              />
                              <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                                {{ errors['description.' + language.code][0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="24"
                            md="12"
                          >
                            <b-form-group
                              :label="$t('form.source.label')"
                              :label-for="'source.' + language.code"
                              :state="errors && errors['source.' + language.code] ? false : null"
                            >
                              <b-form-textarea
                                :id="'source.' + language.code"
                                v-model="item.source[language.code]"
                                :state="errors && errors['source.' + language.code] ? false : null"
                              />
                              <b-form-invalid-feedback v-if="errors && errors['source.' + language.code]">
                                {{ errors['source.' + language.code][0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-tab>
                    </b-tabs>
                  </b-tab>
                  <b-tab :title="$t('market.products.tabs.data.label')">
                    <b-row>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group :label="$t('form.avatar.label')">
                          <!-- media -->
                          <b-media no-body>
                            <b-media-aside>
                              <b-link>
                                <b-img
                                  ref="previewEl"
                                  rounded
                                  :src="$options.filters.mediaUrl(item, 'avatar', '150x150')"
                                  height="80"
                                />
                              </b-link>
                              <!--/ avatar -->

                              <!-- reset -->
                              <b-button
                                v-if="item.avatar"

                                variant="outline-secondary"
                                size="sm"
                                class="mb-75 mr-75"
                                @click="item.avatar = null"
                              >
                                X
                              </b-button>
                              <!--/ reset -->
                            </b-media-aside>

                            <b-media-body class="mt-75 ml-75">
                              <InitFileManager
                                field="avatar"
                                type="product_avatar"
                                :multiple="false"
                                @fm-selected="selectedAvatar"
                              />

                              <b-row
                                v-if="errors && errors.avatar"
                              >
                                <b-col cols="12">
                                  <b-form-invalid-feedback :state="false">
                                    {{ errors.avatar[0] }}
                                  </b-form-invalid-feedback>
                                </b-col>
                              </b-row>
                              <b-row
                                v-if="errors && errors['avatar.type']"
                              >
                                <b-col cols="12">
                                  <b-form-invalid-feedback :state="false">
                                    {{ errors['avatar.type'][0] }}
                                  </b-form-invalid-feedback>
                                </b-col>
                              </b-row>

                            </b-media-body>
                          </b-media>
                          <!--/ media -->
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          :label="$t('form.author_id.label')"
                          label-for="author_id"
                          :state="errors && errors.user_id ? false : null"
                        >
                          <v-select
                            id="author_id"
                            v-model="item.user_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="userOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                            input-id="author_id"
                            autocomplete="off"
                            :searchable="true"
                            @search="searchUser"
                          />
                          <b-form-invalid-feedback v-if="errors && errors.user_id">
                            {{ errors.user_id[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          :label="$t('form.link.label')"
                          label-for="link"
                        >
                          <b-form-input
                            id="link"
                            v-model="item.link"
                            :state="errors && errors.link ? false : null"
                          />

                          <b-form-invalid-feedback v-if="errors && errors.link">
                            {{ errors.link[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>

                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          :label="$t('form.type.label')"
                          label-for="type"
                          :state="errors && errors.type ? false : null"
                        >
                          <v-select
                            id="type"
                            v-model="item.type"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="productTypeOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                            input-id="type"
                          />
                          <b-form-invalid-feedback v-if="errors && errors.type">
                            {{ errors.type[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          :label="$t('form.categories.label')"
                          label-for="categories"
                          :state="errors && errors.categories ? false : null"
                        >
                          <v-select
                            id="categories"
                            v-model="item.categories"
                            :multiple="true"
                            :clearable="false"
                            :options="categoryOptions"
                            :reduce="val => val.value"
                            input-id="categories"
                          />
                          <b-form-invalid-feedback v-if="errors && errors.categories">
                            {{ errors.categories[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          :label="$t('form.stickers.label')"
                          label-for="stickers"
                          :state="errors && errors.stickers ? false : null"
                        >
                          <v-select
                            id="stickers"
                            v-model="item.stickers"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="stickersOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                            :multiple="true"
                            input-id="stickers"
                          />
                          <b-form-invalid-feedback v-if="errors && errors.stickers">
                            {{ errors.stickers[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>

                      <b-col
                        v-if="1 === 2"
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          :label="$t('form.language.label')"
                          label-for="language"
                          :state="errors && errors.language ? false : null"
                        >
                          <v-select
                            id="language"
                            v-model="item.language"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="languageOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                            input-id="language"
                          />
                          <b-form-invalid-feedback v-if="errors && errors.language">
                            {{ errors.language[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          :label="$t('form.private.label')"
                          label-for="private"
                          :state="errors && errors.private ? false : null"
                        >
                          <v-select
                            id="private"
                            v-model="item.private"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="statusOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                            input-id="private"
                          />
                          <b-form-invalid-feedback v-if="errors && errors.private">
                            {{ errors.private[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          :label="$t('form.sex.label')"
                          label-for="sex"
                          :state="errors && errors.sex ? false : null"
                        >
                          <v-select
                            id="sex"
                            v-model="item.sex"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="statusOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                            input-id="sex"
                          />
                          <b-form-invalid-feedback v-if="errors && errors.sex">
                            {{ errors.sex[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>

                      <b-col
                        v-if="item.sex === 'enabled'"
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          :label="$t('form.sex_ratio.label')"
                          label-for="sex_ratio"
                        >
                          <b-input-group>
                            <b-input-group-prepend
                              is-text
                              class="text-monospace boys"
                            >
                              Ч - {{ item.sex_ratio }}%
                            </b-input-group-prepend>
                            <b-form-input
                              id="sex_ratio"
                              v-model="item.sex_ratio"
                              type="range"
                              number
                              min="0"
                              max="100"
                              step="1"
                              :state="errors && errors.sex_ratio ? false : null"
                            />
                            <b-input-group-append
                              is-text
                              class="text-monospace girls"
                            >
                              Ж - {{ 100 - item.sex_ratio }}%
                            </b-input-group-append>
                          </b-input-group>

                          <b-form-invalid-feedback v-if="errors && errors.sex_ratio">
                            {{ errors.sex_ratio[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-tab>
                  <b-tab :title="$t('market.products.tabs.prices.label')">
                    <div class="repeater-form">
                      <b-row
                        v-for="(price, index) in item.prices"
                        :id="'price' + index"
                        :key="index"
                        ref="row"
                      >
                        <b-col
                          cols="12"
                          md="6"
                        >
                          <b-form-group
                            :label="$t('form.type.label')"
                            :label-for="'prices.' + index + '.type'"
                            :state="errors && errors['prices.' + index + '.type'] ? false : null"
                          >
                            <v-select
                              :id="'prices.' + index + '.type'"
                              v-model="price.type"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="priceTypeOptions"
                              :reduce="val => val.value"
                              :clearable="false"
                              :input-id="'prices.' + index + '.type'"
                            />
                            <b-form-invalid-feedback v-if="errors && errors['prices.' + index + '.type']">
                              {{ errors['prices.' + index + '.type'][0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                        >
                          <b-form-group
                            :label="$t('form.price.label')"
                            :label-for="'prices.' + index + '.price'"
                          >
                            <b-form-input
                              :id="'prices.' + index + '.price'"
                              v-model="price.price"
                              :state="errors && errors['prices.' + index + '.price'] ? false : null"
                            />

                            <b-form-invalid-feedback v-if="errors && errors['prices.' + index + '.price']">
                              {{ errors['prices.' + index + '.price'][0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <!-- Remove Button -->
                        <b-col
                          cols="12"
                          md="6"
                          class="mb-50"
                        >
                          <b-button
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="outline-danger"
                            class="mt-0 mt-md-2"
                            @click="removePrice(index)"
                          >
                            <feather-icon
                              icon="XIcon"
                              class="mr-25"
                            />
                            <span>{{ $t('general.delete') }}</span>
                          </b-button>
                        </b-col>
                        <b-col cols="12">
                          <hr>
                        </b-col>
                      </b-row>
                    </div>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="addPrice"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.add') }}</span>
                    </b-button>

                    <b-row
                      v-if="errors && errors.prices"
                    >
                      <b-col cols="12">
                        <b-form-invalid-feedback :state="false">
                          {{ errors.prices[0] }}
                        </b-form-invalid-feedback>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          :label="$t('form.has_sale.label')"
                          label-for="has_sale"
                          :state="errors && errors.has_sale ? false : null"
                        >
                          <v-select
                            id="has_sale"
                            v-model="item.has_sale"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="statusOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                            input-id="has_sale"
                          />
                          <b-form-invalid-feedback v-if="errors && errors.has_sale">
                            {{ errors.has_sale[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="item.has_sale === 'enabled'"

                        cols="12"
                        md="6"
                      >
                        <b-form-group
                          :label="$t('form.sale.label')"
                          label-for="sale"
                        >
                          <b-form-input
                            id="sale"
                            v-model="item.sale"
                            :state="errors && errors.sale ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors.sale">
                            {{ errors.sale[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-tab>
                  <b-tab :title="$t('market.products.tabs.discounts.label')">
                    <div class="repeater-form">
                      <b-row
                        v-for="(discount, index) in item.discounts"
                        :id="'discount' + index"
                        :key="index"
                        ref="row"
                      >
                        <b-col
                          cols="12"
                          md="6"
                        >
                          <b-form-group
                            :label="$t('form.type.label')"
                            :label-for="'discounts.' + index + '.type'"
                            :state="errors && errors['discounts.' + index + '.type'] ? false : null"
                          >
                            <v-select
                              :id="'discounts.' + index + '.type'"
                              v-model="discount.type"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="priceTypeOptions"
                              :reduce="val => val.value"
                              :clearable="false"
                              :input-id="'discounts.' + index + '.type'"
                            />
                            <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.type']">
                              {{ errors['discounts.' + index + '.type'][0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                        >
                          <b-form-group
                            :label="$t('form.price.label')"
                            :label-for="'discounts.' + index + '.price'"
                          >
                            <b-form-input
                              :id="'discounts.' + index + '.price'"
                              :ref="'discounts' + index + 'price'"
                              v-model="discount.price"
                              :type="`number`"
                              :step="`1`"
                              :state="errors && errors['discounts.' + index + '.price'] ? false : null"
                            />

                            <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.price']">
                              {{ errors['discounts.' + index + '.price'][0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                        >
                          <b-form-group
                            :label="$t('form.started_at.label')"
                            :label-for="'discounts.' + index + '.started_at'"
                            :state="errors && errors['discounts.' + index + '.started_at'] ? false : null"
                          >
                            <b-form-datepicker
                              :id="'discounts.' + index + '.started_at'"
                              v-model="discount.started_at"
                              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            />

                            <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.started_at']">
                              {{ errors['discounts.' + index + '.started_at'][0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                        >
                          <b-form-group
                            :label="$t('form.ended_at.label')"
                            :label-for="'discounts.' + index + '.ended_at'"
                            :state="errors && errors['discounts.' + index + '.ended_at'] ? false : null"
                          >
                            <b-form-datepicker
                              :id="'discounts.' + index + '.ended_at'"
                              v-model="discount.ended_at"
                              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            />

                            <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.ended_at']">
                              {{ errors['discounts.' + index + '.ended_at'][0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <!-- Remove Button -->
                        <b-col
                          cols="12"
                          md="6"
                          class="mb-50"
                        >
                          <b-button
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="outline-danger"
                            class="mt-0 mt-md-2"
                            @click="removeDiscount(index)"
                          >
                            <feather-icon
                              icon="XIcon"
                              class="mr-25"
                            />
                            <span>{{ $t('general.delete') }}</span>
                          </b-button>
                        </b-col>
                        <b-col cols="12">
                          <hr>
                        </b-col>
                      </b-row>
                    </div>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="addDiscount"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.add') }}</span>
                    </b-button>

                    <b-row
                      v-if="errors && errors.discounts"
                    >
                      <b-col cols="12">
                        <b-form-invalid-feedback :state="false">
                          {{ errors.discounts[0] }}
                        </b-form-invalid-feedback>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>
                  </b-tab>
                </b-tabs>
              </b-form>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-card>
              <h4>Ручне встановлення охватів на сьогодні</h4>
              <br>
              <div class="repeater-form">
                <b-row
                  v-for="(discount, index) in item.histories"
                  :id="'discount' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="$t('catalog.item.descriptions.subscribers')"
                      :label-for="'histories.' + index + '.subscribers'"
                    >
                      <b-form-input
                        :id="'histories.' + index + '.subscribers'"
                        :ref="'histories' + index + 'subscribers'"
                        v-model="discount.subscribers"
                        :type="`number`"
                        :step="`1`"
                        :state="errors && errors['histories.' + index + '.subscribers'] ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['histories.' + index + '.subscribers']">
                        {{ errors['histories.' + index + '.subscribers'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="$t('catalog.item.descriptions.views')"
                      :label-for="'histories.' + index + '.views'"
                    >
                      <b-form-input
                        :id="'histories.' + index + '.views'"
                        :ref="'histories' + index + 'views'"
                        v-model="discount.views"
                        :type="`number`"
                        :step="`1`"
                        :state="errors && errors['histories.' + index + '.views'] ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['histories.' + index + '.views']">
                        {{ errors['histories.' + index + '.views'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="$t('catalog.item.descriptions.mentions')"
                      :label-for="'histories.' + index + '.mentions'"
                    >
                      <b-form-input
                        :id="'histories.' + index + '.mentions'"
                        :ref="'histories' + index + 'mentions'"
                        v-model="discount.mentions"
                        :type="`number`"
                        :step="`1`"
                        :state="errors && errors['histories.' + index + '.mentions'] ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['histories.' + index + '.mentions']">
                        {{ errors['histories.' + index + '.mentions'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="$t('catalog.item.descriptions.posts')"
                      :label-for="'histories.' + index + '.posts'"
                    >
                      <b-form-input
                        :id="'histories.' + index + '.posts'"
                        :ref="'histories' + index + 'posts'"
                        v-model="discount.posts"
                        :type="`number`"
                        :step="`1`"
                        :state="errors && errors['histories.' + index + '.posts'] ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['histories.' + index + '.posts']">
                        {{ errors['histories.' + index + '.posts'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>

                  <!-- Remove Button -->
                  <b-col
                    lg="12"
                    md="12"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      block
                      class="mt-0 mt-md-2"
                      @click="removeHistory(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-if="!item.histories.length"
                variant="primary"
                block
                @click="addHistory"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row class="mt-2">
                <b-col
                  v-if="errors && errors.histories"
                  cols="12"
                >
                  <b-form-invalid-feedback :state="false">
                    {{ errors.histories[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </b-card>
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.status.label')"
                    label-for="status"
                    :state="errors && errors.status ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptionsFull"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.status">
                      {{ errors.status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.comment_status.label')"
                    label-for="comment_status"
                  >
                    <b-form-textarea
                      id="comment_status"
                      v-model="item.comment_status"
                      :state="errors && errors.comment_status ? false : null"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-button
                    block
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    @click="onSubmit"
                  >
                    {{ $t('general.save') }}
                  </b-button>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-button
                    block
                    type="button"
                    variant="outline-secondary"
                    :to="{ name: 'admin:products-index' }"
                  >
                    {{ $t('general.cancel') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :title="$t('orders.changes_history')">
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-button
                    block
                    variant="info"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    @click="connectTgstat"
                  >
                    Hard connect to TGStat
                  </b-button>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-button
                    block
                    variant="warning"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    @click="importTgstat"
                  >
                    Import data from TGStat
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
            <b-card>
              <b-table
                class="position-relative"
                :items="item.history"
                responsive
                :fields="historyColumns"
                primary-key="id"
                show-empty
                :empty-text="$t('market.table.settings.empty_text')"
              />
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </b-overlay>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'

import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { mapGetters } from 'vuex'

import InitFileManager from '@/components/InitFileManager'

export default {
  components: {
    InitFileManager,
  },
  mixins: [GlobalMixin],
  data() {
    return {
      item: null,
      languages: [],
      loading: {
        history: false,
        categories: false,
        stocks: false,
        roles: false,
        attributes: false,
      },
      categoryOptions: [],
      stickersOptions: [],
      userOptions: [],
      fmPath: '',
      historyColumns: [
        { key: 'created', label: this.$t('market.table.fields.created_at'), sortable: false },
        { key: 'subscribers', label: this.$t('catalog.item.labels.subscribers'), sortable: false },
        { key: 'views', label: this.$t('catalog.item.labels.views'), sortable: false },
        { key: 'mentions', label: this.$t('catalog.item.labels.mentions'), sortable: false },
        { key: 'posts', label: this.$t('catalog.item.labels.posts'), sortable: false },
      ],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get(`/api/admin/products/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)

        if (!_.isNull(data.user) && !_.find(this.userOptions, { value: data.user.id })) {
          this.userOptions.push({
            value: data.user.id,
            label: data.user.name,
          })
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'products-index' })
        }
      })

    if (!Object.keys(this.categoryOptions).length) {
      this.loading.categories = false

      await this.$http.get('/api/admin/categories', {
        params: {
          type: 'product',
          status: 'enabled',
        },
      })
        .then(response => {
          this.categoryOptions = this.$options.filters.transformForVSelect(response.data, 'id', 'title')
          this.loading.categories = true
        })
        .catch(() => {
          this.loading.categories = true
        })
    }
    if (!Object.keys(this.stickersOptions).length) {
      this.loading.categories = false

      await this.$http.get('/api/stickers', { params: { status: 'enabled', per_page: 100 } })
        .then(response => {
          this.stickersOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
          this.loading.categories = true
        })
        .catch(() => {
          this.loading.categories = true
        })
    }
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    selectedAvatar(data) {
      this.item.avatar = data
    },
    onSubmit() {
      const data = {
        title: this.item.title,
        description: this.item.description,
        source: this.item.source,
        link: this.item.link,
        categories: this.item.categories,
        stickers: this.item.stickers,
        avatar: this.item.avatar,
        status: this.item.status,
        comment_status: this.item.comment_status,
        type: this.item.type,
        language: this.item.language,
        private: this.item.private,
        sex: this.item.sex,
        sex_ratio: this.item.sex_ratio,
        prices: this.item.prices,
        discounts: this.item.discounts,
        histories: this.item.histories,
        has_sale: this.item.has_sale,
        sale: this.item.sale,
        user_id: this.item.user_id,
      }

      this.$http.put(`/api/admin/products/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'admin:products-index', query: this.$route.query })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    addPrice() {
      this.item.prices.push({
        price: 1,
        type: null,
      })
    },
    removePrice(index) {
      this.item.prices.splice(index, 1)
    },
    addDiscount() {
      this.item.discounts.push({
        price: 1,
        started_at: new Date(),
        ended_at: new Date(),
        type: null,
      })
    },
    removeDiscount(index) {
      this.item.discounts.splice(index, 1)
    },
    addHistory() {
      this.item.histories.push({
        subscribers: null,
        views: null,
        mentions: null,
        posts: null,
      })
    },
    removeHistory(index) {
      this.item.histories.splice(index, 1)
    },
    transformData(item) {
      const data = {
        id: item.id,
        avatar: this.$options.filters.singleMedia(item.avatar),
        link: item.link,
        categories: item.categories,
        stickers: item.stickers,
        status: item.status,
        comment_status: item.comment_status,
        type: item.type,
        language: item.language,
        private: item.private,
        sex: item.sex,
        sex_ratio: item.sex_ratio,
        price_one: item.price_one,
        price_two: item.price_two,
        price_three: item.price_three,
        price_eternal: item.price_eternal,
        price_repost: item.price_repost,
        has_sale: item.has_sale,
        sale: item.sale,
        history: item.history,
        user_id: item.user.id,
        title: {},
        description: {},
        source: {},
        histories: [],
      }

      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })

        data.title[language.code] = translation ? translation.title : null
        data.description[language.code] = translation ? translation.description : null
        data.source[language.code] = translation ? translation.source : null
      })

      data.prices = _.reduce(item.prices, (result, price) => {
        result.push({
          id: price.id,
          type: price.type,
          price: price.price,
        })
        return result
      }, [])

      data.discounts = _.reduce(item.discounts, (result, discount) => {
        result.push({
          id: discount.id,
          type: discount.type,
          price: discount.price,
          started_at: discount.started_at,
          ended_at: discount.ended_at,
        })
        return result
      }, [])

      data.categories = _.map(data.categories, 'id')
      data.stickers = _.map(data.stickers, 'id')

      // fmPath
      let catUrl = 'uncategorized'
      _.each(item.categories, category => {
        catUrl = category.slug
      })
      this.fmPath = `products/category-${catUrl}/${item.slug}`

      return data
    },
    async searchUser(query) {
      await this.$http.get('/api/users', {
        params: {
          query,
        },
      })
        .then(response => {
          this.userOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'name')
        })
    },
    async reloadChannel() {
      this.loading.history = true
      await this.$http.get(`/api/admin/products/${router.currentRoute.params.id}`)
        .then(response => {
          const { data } = response.data

          this.item = this.transformData(data)
          this.loading.history = false
        })
        .catch(error => {
          this.loading.history = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    importTgstat() {
      this.loading.history = true
      this.$http.post(`/api/admin/products/${this.item.id}/tgstat`)
        .then(res => {
          this.loading.history = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: res.data.data.status === 'ok' ? 'Imported' : res.data.data.error,
              icon: 'AlertCircleIcon',
              variant: res.data.data.status === 'ok' ? 'success' : 'warning',
            },
          })
          this.reloadChannel()
        })
        .catch(error => {
          this.loading.history = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    connectTgstat() {
      this.loading.history = true
      this.$http.post(`/api/admin/products/${this.item.id}/tgstat_add`)
        .then(res => {
          this.loading.history = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: res.data.data.status === 'ok' ? 'Connected' : res.data.data.error,
              icon: 'AlertCircleIcon',
              variant: res.data.data.status === 'ok' ? 'success' : 'warning',
            },
          })
          this.reloadChannel()
        })
        .catch(error => {
          this.loading.history = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.girls .input-group-text {
  background: var(--pink);
  color: var(--white);
}

.boys .input-group-text {
  background: var(--blue);
  color: var(--white);
}

</style>
